import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Link, IconButton } from "@mui/material";
import { ArrowForwardIos, LinkSharp, Sms } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RentPage({rental}) {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const [name, setName] = useState("Purely Palms");
  const [orgName, setOrgName] = useState("");
  const [posWork, setPosWork] = useState("");
  const [affiliateLink, setAffiliateLink] = useState("");
  const [website, setWebsite] = useState([]);
  const [websiteTitle, setWebsiteTitle] = useState([]);
  const [carouselData, setCarouselData] = useState([
    {
      image: "/img/purely_logo.png",
      title: "Purely Palms",
      description: "",
    },
    {
      image: "/img/K0.jpg",
      title: "Purely Palms",
      description: "",
    },
    {
      image: "/img/K1.jpg",
      title: "Front Door",
      description: "",
    },
    {
      image: "/img/K2.jpg",
      title: "Living room",
      description: "",
    },
    {
      image: "/img/K3.jpg",
      title: "Full kitchen",
      description: "",
    },
    {
      image: "/img/K4.jpg",
      title: "Full kitchen",
      description: "",
    },
    {
      image: "/img/K5.jpg",
      title: "Full bathroom",
      description: "",
    },
    {
      image: "/img/K6.jpg",
      title: "Bedroom",
      description: "",
    },
    {
      image: "/img/K7.jpg",
      title: "Bedroom",
      description: "",
    },
    {
      image: "/img/K8.jpg",
      title: "Back Porch",
      description: "",
    },
    {
      image: "/img/K9.jpg",
      title: "Back Porch",
      description: "",
    },
    {
      image: "/img/K10.jpg",
      title: "Pool",
      description: "",
    },
  ]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const isNonStandardPort =
    window.location.port && !["80", "443"].includes(window.location.port);
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    isNonStandardPort ? `:${window.location.port}` : ""
  }`;

  const handleSlideChange = (currentIndex) => {
    setCurrentSlideIndex(currentIndex);
  };

  const settings = {
    dots: true, // Enable dots to show the number of images
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, // Auto-slide every 3 seconds
    afterChange: handleSlideChange,
    arrows: false, // Disable next/prev buttons
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          bottom: "10px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "8px", // Reduced width
          height: "8px", // Reduced height
          backgroundColor: "white",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0px", // Reduced margin for less space between dots
        }}
      ></div>
    ),
    centerMode: true,
    centerPadding: "0px", // Ensure no cut off for arrows
  };

  useEffect(() => {
    setOrgName(`Entire condo in Miramar Beach, Florida
                4 guests 1 bedroom 2 beds 1 bath
                Experience the ultimate in beach-inspired living at Purely Palms @ Miramar Beach. Our 1 bed 1 bath condo has been updated, offering a clean, modern and relaxing atmosphere with a very short walk to the white sands of the Emerald Coast. This centrally located ground floor unit has convenient, hassle-free access to your reserved parking right outside the front door. Plenty of additional parking available in complex. Full washer and dryer is included in unit! Snowbirds Welcome!`);
    setAffiliateLink(`${baseUrl}affiliate_link/971afcee`);
    if(rental == "demo") {
      setWebsite([
        `${baseUrl}/rentals/demo/calendar`,
        `${baseUrl}/rentals/purely_palms/property_rules`,
        `${baseUrl}/rentals/demo/reviews`,
        "https://www.instagram.com/purelypalms_miramarbeach?igsh=c2F3OHI2dzA3Zmhp",
        `${baseUrl}/rentals/purely_palms/refund`,
      ]);
    } else {
      setWebsite([
        `${baseUrl}/rentals/purely_palms/calendar`,
        `${baseUrl}/rentals/purely_palms/property_rules`,
        `${baseUrl}/rentals/purely_palms/reviews`,
        "https://www.instagram.com/purelypalms_miramarbeach?igsh=c2F3OHI2dzA3Zmhp",
        `${baseUrl}/rentals/purely_palms/refund`,
      ]);
    }
    setWebsiteTitle(["Check Availability", "Propery Rules", "Reviews", "Instagram", "Refund",]);
    setPreview("/img/john_doe.png");
  }, []);

  const iconStyle = {
    backgroundColor: "#333", // Adjust the color to match your style
    color: "#fff",
    borderRadius: "50%",
    margin: "0 10px",
  };

  return (
    <>
      <Card sx={{ p: 2 }} className="preview_wrapper">
        <Box className="landing_page_preview_frame">
          <Box className="device-header"></Box>
          {isLoading ? (
            <Box className="qr_page_loader">
              <img className="loader_img" src="/qrlogo.gif" alt="Loading..." />
            </Box>
          ) : (
            <Box
              id="qr_page_prev"
              sx={{
                zoom: 1,
                overflowX: "hidden",
                overflowY: "scroll",
                position: "relative",
                padding: 0,
              }}
              className="qr_page_preview qrp_prev_wrapper thinScrollBar"
            >
              <Box className="page_wrapper">
                <Box className="qrc_page_wrapper thinScrollBar">
                  <Box
                    className="pg_background"
                    sx={{ backgroundImage: "url('')" }}
                  ></Box>
                  <Box className="qrc_page_inner">
                    <Box className="">
                      <Slider {...settings}>
                        {carouselData.map((item, index) => (
                          <div key={index}>
                            <Box
                              className="carousel_image"
                              sx={{
                                backgroundImage: `url(${item.image})`,
                                height: "300px", // Adjust height as needed
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                position: "relative",
                                borderRadius: "10px",
                              }}
                            >
                              {/* Title displayed at the bottom center */}
                              <Box
                                sx={{
                                  position: "relative",
                                  bottom: "10px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                {/* <Typography variant="h6">
                                        {item.title}
                                        </Typography> */}
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    position: "absolute",
                                    bottom: "10px",
                                    left: "10px",
                                    color: "white",
                                    fontSize: "35px",
                                  }}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        ))}
                      </Slider>
                    </Box>

                    {(orgName || posWork) && (
                      <Box className="section qr_cc_card qr_condo_card">
                        <h5><b>Entire condo in Miramar Beach, Florida</b></h5>
                        <br/>
                        <p>4 guests 1 bedroom 2 beds 1 bath</p>
                        <br/>
                        <p>Experience the ultimate in beach-inspired living at Purely Palms @ Miramar Beach. Our 1 bed 1 bath condo has been updated, offering a clean, modern and relaxing atmosphere with a very short walk to the white sands of the Emerald Coast. This centrally located ground floor unit has convenient, hassle-free access to your reserved parking right outside the front door. Plenty of additional parking available in complex. Full washer and dryer is included in unit! Snowbirds Welcome!</p>
                        <Typography variant="body2">{posWork}</Typography>
                      </Box>
                    )}

                    {/* Social Links Section */}
                    <Box className="section qrc_social_links" style={{marginTop: "25px"}}>
                      <ul className="qrc_social_links_list">
                        {website.map(
                          (link, index) =>
                            link &&
                            websiteTitle[index] && (
                              <li key={index} className="qrc_social_link">
                                <Link href={link} target="_blank">
                                  <Box className="qrc_social_icon">
                                    <IconButton>
                                      <LinkSharp />
                                    </IconButton>
                                  </Box>
                                  <Box className="qrc_social_text">
                                    <Typography variant="body1">
                                      {websiteTitle[index]}
                                    </Typography>
                                    {/* <Typography variant="body2">
                                      {link}
                                    </Typography> */}
                                  </Box>
                                  <Box className="qrc_social_action">
                                    <IconButton>
                                      <ArrowForwardIos />
                                    </IconButton>
                                  </Box>
                                </Link>
                              </li>
                            )
                        )}

                      </ul>
                    </Box>

                    {/* Social Links Section */}
                    <Box className="section qrc_caption">
                      <Typography variant="h6">
                        <strong>The QR Store</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Card>
    </>
  );
}
